/* === Plugin styles === */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";

@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~ti-icons/css/themify-icons.css";
@import "~font-awesome/scss/font-awesome";

@import "~compass-mixins/lib/animate";
@import "~compass-mixins/lib/compass";
/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";
/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";

@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";

/* === Components === */

/* === Plugin Overrides === */

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";
.css-yk16xz-control {
  height: 48px !important;
}
.css-yk16xz-control:hover {
  height: 48px !important;
}
.rc-time-picker-input {
  height: 45px !important;
  width: 29vw !important;
  font-size: 14px !important;
}
// .actioncol{
// .ReactTable .rt-th, .ReactTable .rt-td{
//  overflow: visible !important;
//  }
// }\
// .ReactTable .rt-tbody .rt-td:last-child{
//     width: 189px !important;
// }
.border-radius {
  border-radius: 30px;
}

.kanban-toolbar .btn {
  border-radius: 20px !important;
}

.react-datepicker-wrapper {
  display: inline !important;
}
.navbar .navbar-brand-wrapper .navbar-brand img {
  height: 46px !important;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  height: 46px !important;
}
table.fixed {
  table-layout: fixed;
}
table.fixed td {
  overflow: hidden;
}
.sidebar {
  background-color: #101321 !important;
}
.navbar .navbar-brand-wrapper {
  background-color: #101321 !important;
}
.navbar {
  background-color: #101321 !important;
}
.sidebar .nav .nav-item.active > .nav-link {
  background-color: #e00800 !important;
}
.sidebar .nav .nav-item.active > .nav-link .menu-title {
  color: white !important;
}
.btn-success:not(.btn-light) {
  background-color: #e00800 !important;
  border: none !important;
  color: white !important;
  font-weight: 500 !important;
}
.sidebar .nav .nav-item.active > .nav-link .menu-arrow {
  color: #000 !important;
}
.sidebar .nav .nav-item.active > .nav-link i {
  color: white !important;
}
.ReactTable .rt-td {
  font-size: 14px !important;
  padding: 0.5rem !important;
}
.sidebar .nav.sub-menu {
  background-color: #16162c !important;
}
// .sidebar .nav .nav-item .nav-link.menu-expanded{
//     background-color: #e00800 !important;
// }
.sidebar .nav .nav-item .nav-link.hover {
  background-color: #e00800 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: grey !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile img {
  border: 2px solid white !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: grey !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: grey !important;
}
.ant-input-outlined {
  border-color: #c9ccd7 !important;
  height: 48px !important;
  border-radius: none !important;
}
.ant-select-selector {
  border-color: #c9ccd7 !important;
  height: 48px !important;
}
.ant-picker {
  width: 100% !important;
  height: 48px !important;
}

.date-input {
  width: 100% !important;
  border: 1px solid #c9ccd7;
  border-radius: 6px;
  height: 48px;
}

.font-16-bold {
  font-weight: 450;
  font-size: 15px;
}
.ant-input-outlined {
  background: #f1f4f8 !important;
  height: 42px !important;
  border: none !important;
}
.ant-select-selector {
  background: #f1f4f8 !important;
  height: 42px !important;
  border: none !important;
}
.date-input {
  background: #f1f4f8 !important;
  height: 42px !important;
  border: none !important;
}
.label-forms {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.36px;
  color: #343434;
}
.label-forms-signup {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.36px;
  color: #6599ac;
}
.forms-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 21.78px;
  color: #000;
}
.resoucediv {
  background: #f1f4f8;
  padding: 2rem;
  border-radius: 19px;
}
.fieldwrapper .ant-input-outlined {
  background: #ffffff !important;
  height: 42px !important;
  border: none !important;
}
.fieldwrapper .date-input {
  background: #ffffff !important;
  height: 42px !important;
  border: none !important;
}
.fieldwrapper .ant-select-selector {
  background: #ffffff !important;
  min-height: 42px !important;
  border: none !important;
}
.submit-btn {
  background-color: #3c9df8;
  height: 53px;
  border-radius: 36px;
  box-shadow: 0px 5px 14.5px 0px #3c9df885;
}
.add-btn {
  position: absolute;
  width: 90px;
  right: -1rem;
  bottom: 0.5rem;
}
.icon-add {
  color: white;
  position: relative;
  z-index: 3;
  right: 3rem;
  // top: -10px;
}
.maindivskils {
  display: flex;
  justify-content: space-between;
  background-color: #f1f4f8;
  height: 115px;
  border-radius: 19px;
  width: 100%;
  padding: 1rem;
}
.text-skills {
  color: #848a92;
  font-size: 11px;
  font-weight: 400;
}
.innerdiv {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d9d9d9;
  height: 29px;
  padding: 0.5rem;
}
.attechmentmain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainlogin {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.loginmaindiv {
  position: absolute;
  top: 16rem;
  /* padding: 4rem; */
  /* padding-left: 8rem; */
  /* padding-right: 24rem; */
  width: 40%;
  margin-left: 25rem;
}
.loginmaindiv1 {
  position: absolute;
  top: 16rem;
  /* padding: 4rem; */
  /* padding-left: 8rem; */
  /* padding-right: 24rem; */
  width: 40%;
}
.logoiamge {
  height: 323px;
  width: 45vw;
  margin-top: 10rem;
  margin-left: 4rem;
}
.upertext {
  position: absolute;
  top: 0rem;
  padding: 4rem;
  left: 17rem;
}
.upertext1 {
  position: absolute;
  top: 0rem;
  padding: 2rem;
}
.maintext1 {
  font-size: 50px;
  line-height: 93px;
  font-weight: 700;
  color: #47b6de;
}
.logintext3 {
  font-size: 23px;
  font-weight: 400;
  color: #6599ac;
  text-align: center;
  width: 755px;
}
.signin-text {
  font-weight: 300;
  font-size: 50px;
  line-height: 72px;
  color: white;
}
.signin-he {
  font-weight: 500;
  font-size: 26px;
  color: #7a9ba7;
}
.fieldwrapperlogin .ant-input {
  background: #224856 !important;
  height: 42px !important;
  border: none !important;
  height: 62px;
  border-radius: 17px;
  color: white;
}
.dispatchmain {
  height: 114px;
  border-radius: 20px;
  padding: 1rem;
  width: 98%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.dispatchinner {
  border-right: 1px solid #d9d9d9;
}
.dispatchsecinner {
  padding-right: 2rem;
  padding: 7px;
}

.fieldwrapperdispatch .ant-input-outlined {
  border-radius: 30px !important;
}

.fieldwrapperdispatch .ant-select-selector {
  border-radius: 30px !important;
}
.dashbaord-cards {
  height: 25vh;
  padding: 2rem;
  border-radius: 20px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector {
  overflow-y: scroll !important;
}
.signupselect
  :where(.css-dev-only-do-not-override-1rqnfsa).ant-form-item
  .ant-form-item-explain-error {
  margin-top: 7px !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.normal-submit {
  color: white;
  background-color: #3c9df8;
  border: none;
  border-radius: 15px;
  width: 80px;
  height: 29px;
}

.clicked-submit {
  color: white;
  background-color: green;
  border: none;
  border-radius: 15px;
  width: 65px;
  height: 29px;
}

.checkboxinnerwrapper {
  border-right: 1px solid lightgrey;
  padding-left: 1rem;
}
.checkboxinnerwrapper1 {
  border-right: 1px solid lightgrey;
}
.mainwra {
  border: 1px solid lightgrey;
  padding-left: 1rem;
  flex-wrap: nowrap;
  justify-content: left;
}

.next-btn {
  background: #009999;
  color: white;
  border: none;
  border-radius: 18px;
  width: 90px !important;
  height: 35px !important;
}
.prev-btn {
  background: #80aaff;
  color: white;
  border: none;
  border-radius: 18px;
  width: 105px !important;
  height: 35px !important;
}
.previcon {
  position: absolute;
  left: 437px;
  top: 58px;
  color: white;
}
.nexticon {
  position: absolute;
  left: 477px;
  top: 58px;
  color: white;
}
.previcon {
  position: absolute;
  left: 437px;
  top: 58px;
  color: white;
}
.nexticon {
  position: absolute;
  left: 477px;
  top: 58px;
  color: white;
}
.invoicefield .ant-input-outlined {
  height: 30px !important;
  border: 0.5px solid lightgrey !important;
  padding: 0px 0px !important;
}
.searchfield .ant-input-outlined {
  height: 30px !important;
  border: 1px solid grey !important;
}

.label-invoice {
  color: black;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-bottom: 52px !important;
  height: 20px !important;
}
.inovicemain {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.invoicetext {
  font-size: 12px;
  font-weight: 500;
}
.invoicetext1 {
  font-size: 12px;
  font-weight: 400;
}
.invoicetext2 {
  font-size: 15px;
  font-weight: 600;
}
.invoicefield .ant-select-selector {
  height: 30px !important;
  width: 10vw !important;
  border: 0.5px solid lightgrey !important;
  padding: 1px 1px !important;
}
.table th {
  white-space: wrap !important;
  line-height: 1.2 !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #c9ccd7 !important;
}
.table thead th {
  font-size: 12px !important;
  vertical-align: baseline !important;
}
.table-responsive {
  overflow-x: visible !important;
}
.inputInvoice {
  height: 30px !important;
  width: 3.5vw !important;
  border: 0.5px solid lightgrey !important;
  padding: 1px 1px !important;
  border-radius: 6px !important;
}
.inputInvoice1 {
  height: 24px !important;
  width: 8vw !important;
  border: 0.5px solid lightgrey !important;
  padding: 1px 1px !important;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: white !important;
  background: blue !important;
  height: 30.8px !important;
}
.ant-space-gap-col-small {
  overflow: auto !important;
}
.table-responsive {
  overflow: auto !important;
}
.sidebar {
  width: 200px !important;
}
.main-panel {
  width: calc(100% - 190px) !important;
}
.content-wrapper {
  padding: 1rem !important;
  padding-top: 2rem !important;
}
@media (min-width: 992px) {
  .sidebar-icon-only .sidebar {
    width: 70px !important;
  }
  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px) !important;
  }
}
@media (max-width: 992px) {
  .main-panel {
    width: 100% !important;
  }
}
